import React from "react"
import SbEditable from "storyblok-react"

import Header from "./../components/Header"
import PostArticle from "../components/PostArticle"
import Seo from "./../components/Seo"
import Footer from "./../components/Footer"

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    const settings = Object.assign({}, props.pageContext.settings)

    story.content = JSON.parse(story.content)
    settings.content = JSON.parse(settings.content)

    return { story, settings }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let story = this.state.story
    let content = this.state.story.content
    let settings = this.state.settings.content

    return (
      <SbEditable content={content}>
        <Seo
          title={story.name}
          description={content.excerpt}
          image={content.image.filename}
        />
        <Header settings={settings} />
        <PostArticle
          title={story.name}
          slug={story.full_slug}
          date={story.published_at}
          blok={content}
          settings={settings}
        />
        {content.footer &&
          content.footer.map(childBlok => (
            <Footer blok={childBlok} key={childBlok._uid} settings={settings} />
          ))}
      </SbEditable>
    )
  }
}

export default StoryblokEntry
